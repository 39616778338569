export const LOGIN = '/user/auth/login';
export const SIGN_UP = '/user/sign-up';
export const UPDATE_ACCOUNT_INFO = '/user/update-account-info';
export const ADD_VEHICLE = '/vehicles';
export const ADD_LEASING_COMPANY = '/leasing-companies';
export const ADD_DRIVER = '/drivers';
export const GET_USER_VEHICLES = '/vehicles/user-vehicles';
export const ADD_COMPANY_INFO = '/companies';
export const GET_USER_PROFILE = '/user/me';
export const UPDATE_COMPANY_INFO = (companyId) => `/companies/${companyId}`;
export const VALIDATE_ACCESS_CODE = '/user/auth/validate-access-code';
export const VERIFY_PHONE_NUMBER = '/user/verify-phone-number';
export const REQUEST_PHONE_VERIFICATION =
  '/user/request-phone-verification-code';
export const RETRIEVE_COMPANY_DATA = `/companies/retrieve-company-data`;
