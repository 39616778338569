import login from './login';
import signUp from './sign-up';
import setAccountType from './setAccountType';
import verifyPhone from './verifyPhone';
import updateAccountInfo from './updateAccountInfo';
import setAccountPin from './setAccountPin';
import retrieveCompanyData from './retrieveCompanyData';
import viewRetrievedCompanyData from './viewRetrievedCompanyData';

export default [
  login,
  signUp,
  setAccountType,
  verifyPhone,
  updateAccountInfo,
  setAccountPin,
  retrieveCompanyData,
  viewRetrievedCompanyData,
];
