import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import { authApi } from './apiQueries/authQueries';
import { vehicleAPi } from './apiQueries/vehicleQueries';
import vehicleReducer from './slices/vehicleSlice';
import { leasingCompanyApi } from './apiQueries/leasingCompanyQueries';
import leasingCompanyReducer from './slices/leasingCompanySlice';
import { driverApi } from './apiQueries/driverQueries';
import driverReducer from './slices/driverSlice';
import { companyApi } from './apiQueries/companyQueries';
import companyReducer from './slices/companySlice';

const rootReducer = combineReducers({
  auth: authReducer,
  vehicles: vehicleReducer,
  leasingCompanies: leasingCompanyReducer,
  drivers: driverReducer,
  company: companyReducer,
  [authApi.reducerPath]: authApi.reducer,
  [vehicleAPi.reducerPath]: vehicleAPi.reducer,
  [leasingCompanyApi.reducerPath]: leasingCompanyApi.reducer,
  [leasingCompanyApi.reducerPath]: leasingCompanyApi.reducer,
  [driverApi.reducerPath]: driverApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
});

export default rootReducer;
