import { createSlice } from '@reduxjs/toolkit';
import { leasingCompanyApi } from '../apiQueries/leasingCompanyQueries';

const leasingCompanySlice = createSlice({
  name: 'leasingCompanies',
  initialState: {
    isLoading: false,
    error: null,
    companies: [],
    selectedVehicle: null,
  },
  reducers: {
    selectVehicle: (state, action) => {
      state.selectedVehicle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      leasingCompanyApi.endpoints.addLeasingCompany.matchFulfilled,
      (state, { data }) => {
        state.isLoading = false;
        state.companies = [...state.companies, data];
      }
    );
  },
});

export default leasingCompanySlice.reducer;
