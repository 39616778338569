import { createSlice } from '@reduxjs/toolkit';
import { driverApi } from '../apiQueries/driverQueries';

const driverSlice = createSlice({
  name: 'drivers',
  initialState: {
    isLoading: false,
    error: null,
    drivers: [],
    selectedVehicle: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      driverApi.endpoints.addDriver.matchFulfilled,
      (state, { data }) => {
        state.isLoading = false;
        state.drivers = [...state.drivers, data];
      }
    );
  },
});

export default driverSlice.reducer;
