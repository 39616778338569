import { createSlice } from '@reduxjs/toolkit';
import { vehicleAPi } from '../apiQueries/vehicleQueries';

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState: {
    isLoading: false,
    error: null,
    cars: [],
    selectedVehicle: null,
    addedVehicle: null,
    hasVehicles: false,
  },
  reducers: {
    selectVehicle: (state, action) => {
      state.selectedVehicle = action.payload;
    },
    setHasVehicles: (state, action) => {
      state.hasVehicles = action.payload;
    },
    addVehicle: (state, action) => {
      state.cars = [...state.cars, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      vehicleAPi.endpoints.addVehicle.matchFulfilled,
      (state, payload) => {
        state.isLoading = false;
        state.cars = [...state.cars, payload.data];
        state.addedVehicle = payload.data;
      }
    );
    builder.addMatcher(
      vehicleAPi.endpoints.getUserVehicles.matchFulfilled,
      (state, { data }) => {
        state.isLoading = false;
        state.cars = data || [
          {
            registrationNumber: 'JJ-124-F',
          },
          {
            registrationNumber: 'AD-V4-12-G',
          },
          {
            registrationNumber: 'RGB-123-C',
          },
        ];
      }
    );
  },
});

export const { selectVehicle, addVehicle, setHasVehicles } =
  vehicleSlice.actions;
export default vehicleSlice.reducer;
