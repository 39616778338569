import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { ADD_LEASING_COMPANY } from '../endpoints';

export const leasingCompanyApi = createApi({
  reducerPath: 'leasingCompanyApi',
  baseQuery,
  endpoints: (builder) => ({
    addLeasingCompany: builder.mutation({
      query: (leasingCompanyData) => ({
        url: ADD_LEASING_COMPANY,
        method: 'POST',
        body: leasingCompanyData,
      }),
    }),
  }),
});

export const { useAddLeasingCompanyMutation } = leasingCompanyApi;
