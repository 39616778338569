import './page-loader.scss';

function PageLoader() {
  return (
    <div className="loader-container items-center flex space-around">
      <div className="lds-grid">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default PageLoader;
