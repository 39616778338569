import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../apiQueries/authQueries';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    error: null,
    user: null,
    token: null,
    isLoggedIn: false,
    accountCompleted: false,
    accountType: '',
  },
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
      state.accountCompleted = false;
      state.requestPIN = true;
    },
    completeCompanyAccount: (state) => {
      state.accountCompleted = true;
      state.user.hasCompany = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.signUp.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.user = payload.data;
        state.token = payload.token;
        state.isLoggedIn = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.user = data;
        state.token = payload.token;
        state.user = data;
        state.accountType = data.accountType;
        state.accountCompleted = data.accountCompleted;
        state.isLoggedIn = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.updateAccountInfo.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.user = data;
        state.accountType = data.accountType;
        state.accountCompleted = data.accountCompleted;
      }
    );
    builder.addMatcher(
      authApi.endpoints.getUserProfile.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;
        state.isLoading = false;
        state.user = data;
        state.accountType = data.accountType;
        state.accountCompleted = data.accountCompleted;
      }
    );

    builder.addMatcher(
      authApi.endpoints.verifyPhoneNumber.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 200) {
          state.user.phoneNumberVerified = true;
        }
      }
    );
  },
});

export const { logOut, completeCompanyAccount } = authSlice.actions;
export default authSlice.reducer;
