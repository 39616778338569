import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { ADD_VEHICLE, GET_USER_VEHICLES } from '../endpoints';

export const vehicleAPi = createApi({
  reducerPath: 'vehicleAPi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    addVehicle: builder.mutation({
      query: (vehicleData) => ({
        url: ADD_VEHICLE,
        method: 'POST',
        body: vehicleData,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          vehicleAPi.util.updateQueryData('getUserVehicles', id, (draft) => {
            draft.data = [...draft.data, { ...patch, id }];
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getUserVehicles: builder.query({
      query: () => ({
        url: GET_USER_VEHICLES,
        cache: false,
        cacheTime: 0,
      }),
    }),
  }),
});

export const { useAddVehicleMutation, useGetUserVehiclesQuery } = vehicleAPi;
