import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import {
  ADD_COMPANY_INFO,
  UPDATE_COMPANY_INFO,
  RETRIEVE_COMPANY_DATA,
} from '../endpoints';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery,
  endpoints: (builder) => ({
    addCompanyInfo: builder.mutation({
      query: (companyData) => ({
        url: ADD_COMPANY_INFO,
        method: 'POST',
        body: companyData,
      }),
    }),
    updateCompanyInfo: builder.mutation({
      query: ({ id, ...companyData }) => ({
        url: UPDATE_COMPANY_INFO(id),
        method: 'PUT',
        body: companyData,
      }),
    }),
    retrieveCompanyData: builder.mutation({
      query: (cui) => ({
        method: 'POST',
        url: RETRIEVE_COMPANY_DATA,
        body: {
          cui,
          date: String(new Date().toISOString().split('T')[0]),
        },
      }),
    }),
  }),
});

export const {
  useAddCompanyInfoMutation,
  useUpdateCompanyInfoMutation,
  useRetrieveCompanyDataMutation,
} = companyApi;
