import { createSlice } from '@reduxjs/toolkit';
import { companyApi } from '../apiQueries/companyQueries';

const companySlice = createSlice({
  name: 'company',
  initialState: {
    isLoading: false,
    error: null,
    company: null,
    selectedVehicle: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      companyApi.endpoints.retrieveCompanyData.matchFulfilled,
      (
        state,
        {
          payload: {
            data: {
              found: [companyData],
            },
          },
        }
      ) => {
        state.isLoading = false;
        const {
          date_generale: {
            cui,
            nrRegCom,
            denumire,
            adresa,
            stare_inregistrare: stareInregistrare,
          } = {},
        } = companyData || {};

        state.company = {
          cui,
          nrRegCom,
          denumire,
          adresa,
          stareInregistrare,
        };
      }
    );
  },
});

export default companySlice.reducer;
