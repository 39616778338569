import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import { authApi } from './apiQueries/authQueries';
import { vehicleAPi } from './apiQueries/vehicleQueries';
import { leasingCompanyApi } from './apiQueries/leasingCompanyQueries';
import { companyApi } from './apiQueries/companyQueries';
import { driverApi } from './apiQueries/driverQueries';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      vehicleAPi.middleware,
      leasingCompanyApi.middleware,
      companyApi.middleware,
      driverApi.middleware,
    ]),
});

export default store;
export const persistor = persistStore(store);
