import '@assets/styles/index.scss';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import React from 'react';
import PageLoader from '@components/PageLoader';
import ErrorBoundary from '@HOC/ErrorBoundary';
// import PageHeader from '@components/PageHeader';
import ProtectedRoute from '@HOC/ProtectedRoute';
import routes from '@routes';

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <React.Suspense fallback={<PageLoader />}>
          {/* <PageHeader /> */}
          <Routes>
            {routes.map(({ path, element: Component, isProtected, title }) => {
              document.title = title;
              if (isProtected && !(path === '/login' || path === '/sign-up')) {
                return (
                  <Route
                    key={path}
                    path={path}
                    exact
                    element={
                      <ProtectedRoute path={path}>
                        <Component />
                      </ProtectedRoute>
                    }
                  />
                );
              }
              return (
                <Route exact key={path} path={path} element={<Component />} />
              );
            })}

            <Route path="*" element={<div>Page not found</div>} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
