import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import { ADD_DRIVER } from '../endpoints';

export const driverApi = createApi({
  reducerPath: 'driverApi',
  refetchOnReconnect: true,
  baseQuery,
  endpoints: (builder) => ({
    addDriver: builder.mutation({
      query: (driverData) => ({
        url: ADD_DRIVER,
        method: 'POST',
        body: driverData,
      }),
    }),
  }),
});

export const { useAddDriverMutation } = driverApi;
