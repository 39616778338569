import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { accountTypes } from '@constants/index';

function ProtectedRoute({ children, path }) {
  const { isLoggedIn, accountType, user, accountCompleted } = useSelector(
    (state) => state.auth
  );

  const { cars } = useSelector((state) => state.vehicles);
  //   if (!isLoggedIn) return <Navigate replace to="/" />;
  //   if (!isLoggedIn) return <Navigate replace to="/login" />;

  if (isLoggedIn && !accountType) {
    return path === '/set-account-type' ? (
      children
    ) : (
      <Navigate to="/set-account-type" replace />
    );
  }

  if (isLoggedIn && accountType && path === '/set-account-type') {
    return <Navigate to="/" replace />;
  }

  if (isLoggedIn && accountType && !user?.phoneNumberVerified) {
    return path === '/verify-phone' ? (
      children
    ) : (
      <Navigate to="/verify-phone" replace />
    );
  }

  if (
    !accountCompleted &&
    isLoggedIn &&
    accountType &&
    user?.phoneNumberVerified
  ) {
    return [
      '/update-account-info',
      '/retrieve-company-data',
      '/view-retrieved-company-data',
    ].includes(path) ? (
      children
    ) : (
      <Navigate
        to={
          user?.accountType === accountTypes.INDIVIDUAL
            ? '/update-account-info'
            : '/retrieve-company-data'
        }
        replace
      />
    );
  }

  if (isLoggedIn && accountCompleted && !cars.length) {
    return path.includes('vehicle') ? (
      children
    ) : (
      <Navigate replace to="/retrieve-vehicle-info" />
    );
  }

  return children;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
