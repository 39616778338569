import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '@store/apiQueries/baseQuery';
import {
  LOGIN,
  SIGN_UP,
  UPDATE_ACCOUNT_INFO,
  GET_USER_PROFILE,
  VALIDATE_ACCESS_CODE,
  VERIFY_PHONE_NUMBER,
  REQUEST_PHONE_VERIFICATION,
} from '../endpoints';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN,
        method: 'POST',
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (userDetails) => ({
        url: SIGN_UP,
        method: 'POST',
        body: userDetails,
      }),
    }),
    updateAccountInfo: builder.mutation({
      query: (accountDetails) => ({
        url: UPDATE_ACCOUNT_INFO,
        method: 'PUT',
        body: accountDetails,
      }),
    }),
    getUserProfile: builder.query({
      query: () => ({
        url: GET_USER_PROFILE,
      }),
    }),
    validateAccessCode: builder.mutation({
      query: (accessCode) => ({
        url: VALIDATE_ACCESS_CODE,
        method: 'POST',
        body: { accessCode },
      }),
    }),
    verifyPhoneNumber: builder.mutation({
      query: (body) => ({
        url: VERIFY_PHONE_NUMBER,
        method: 'POST',
        body,
      }),
    }),
    requestPhoneVerification: builder.mutation({
      query: (phoneNumber) => ({
        url: REQUEST_PHONE_VERIFICATION,
        method: 'POST',
        body: { phoneNumber },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useUpdateAccountInfoMutation,
  useGetUserProfileQuery,
  useValidateAccessCodeMutation,
  useVerifyPhoneNumberMutation,
  useRequestPhoneVerificationMutation,
} = authApi;
